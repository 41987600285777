/* src/chatbot_components/MainInterface/Nodes/MobiusNode.css */

.mobius-node-card {
    position: relative;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    max-width: 1100px;
    margin: 10px auto;
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  }
  
  .mobius-node-title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 12px;
  }
  
  /* The row of domain, plane, sphere */
  .mobius-plots-row {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-bottom: 16px;
  }
  
  .plot-wrapper {
    width: 250px;
    height: 250px;
    background: #fafafa;
    border: 1px solid #eee;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Param section below */
  .mobius-params {
    border-top: 1px solid #ccc;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .param-row {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
  }
  
  .param-row label {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 0.9rem;
  }
  
  .error-msg {
    color: red;
    margin-top: 8px;
  }
  
  /* Overlay to block interactions if user not pressing Control */
  .command-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128,128,128,0.35);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  
  .overlay-text {
    color: #282828;
    font-size: 1rem;
    font-weight: bold;
    background: #fff;
    padding: 8px 12px;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  }
  
  .command-key-symbol {
    background: #eee;
    padding: 2px 4px;
    border-radius: 4px;
    margin: 0 3px;
  }
  