/* src/chatbot_components/MainInterface/FloatingBar.css */

/* Wrapper for entire bar plus optional message */
.floating-bar-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  width: 50%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Small note that appears if command is pressed */
.command-pressed-message {
  background: #f8e08e;
  color: #333;
  padding: 6px 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  font-weight: bold;
  text-align: center;
  font-size: 0.9rem;
}

.floating-bar {
  width: 100%;
  padding: 8px 16px;
  background-color: var(--input-background);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

/* If bar is "disabled" because command key is pressed, optionally dim it */
.bar-disabled {
  opacity: 0.7;
}

.floating-input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: var(--node-background);
  margin-right: 10px;
  font-size: 16px;
  outline: none;
  color: var(--text-color);
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
}

.button-group {
  display: flex;
  gap: 8px;
}

.floating-button,
.refresh-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px 20px; 
  font-size: 16px;
  color: #ffffff;
}

/* Primary button */
.floating-button {
  background-color: #007aff;
}

.floating-button:hover {
  background-color: #005bb5;
}

/* Secondary/refresh button */
.refresh-button {
  background-color: #ff9500;
}

.refresh-button:hover {
  background-color: #cc7a00;
}

/* If button is disabled, slightly dim & remove hover highlight */
.floating-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #007aff;
}
