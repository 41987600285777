/* src/chatbot_components/MainInterface/Menu/Menu.css */

.sidebar-menu-left {
  position: absolute;
  z-index: 10;
  top: 2.5vh;
  left: 20px;
  width: 250px;
  height: 95vh;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  padding: 15px;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.menu-title {
  margin: 0;
  font-size: 16px;
  text-align: left;
}

.course-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  gap: 6px;
  margin-bottom: 15px;
}

.course-badge {
  background-color: #007bff;
  color: #fff;
  padding: 3px 8px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
}

.role-badge {
  background-color: #2ccb2f; 
  color: #fff;
  padding: 3px 8px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
}

.menu-button {
  width: 90%;
  margin-bottom: 10px;
  padding: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-weight: 600;
}

.menu-button:hover {
  opacity: 0.85;
}

.settings-button {
  background: #007bff;
}

.logout-button {
  background: #dc3545;
}

.switch-button {
  background: #6f42c1;
}

.menu-divider {
  margin: 15px 0;
  border: none;
  border-top: 1px solid #ccc;
}

/* The new “What’s New?” button: teal-ish */
.whatsnew-button {
  background: #17a2b8;
}

/* The “Tutorial” button: slightly blue, disabled style */
.tutorial-button {
  background: #6f9fc9;
  opacity: 0.6;
  cursor: not-allowed;
}
