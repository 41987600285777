/* src/chatbot_components/MainInterface/FloatingBar/WaitTimerBox.css */

.wait-timer-box {
    position: fixed;
    bottom: 80px; /* Adjust as needed */
    right: 20px;
    background: #fef8c0;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }
  
  .wait-timer-text {
    margin: 0;
    font-size: 14px;
    color: #333;
  }
  