/* src/chatbot_components/MainInterface/Nodes/InitialOptionsNode.css */

.initial-options-node {
  width: 650px;
  border: 2px solid #2196f3;
  background-color: #e3f2fd;
  border-radius: 10px;
  padding: 20px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.initial-options-title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/* We’ll do 4 columns at ~140px each => 560 + gap = ~600 total, fitting in 650px */
.initial-options-card-container {
  display: grid;
  grid-template-columns: repeat(4, 140px);
  grid-gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: start;
}

/* Each card */
.initopt-card {
  position: relative;
  background-color: #fff;
  border: 1px solid #90caf9;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: box-shadow 0.2s ease-in-out;
  padding: 10px;
}

.initopt-card:hover {
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}

.initopt-card img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  margin: 0 auto;
  display: block;
}

/* The text under the image */
.option-text {
  margin-top: 8px;
  border-top: 1px solid #eee;
  padding-top: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.85rem;
}

/* Green highlight if selected */
.initopt-card.used-card {
  border: 2px solid #4caf50 !important;
  box-shadow: 0 0 4px rgba(76,175,80,0.5);
}

/* Grey out the unselected if one is chosen */
.initopt-card.greyed-out {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

/* badge styling */
.option-badge {
  display: inline-block;
  padding: 2px 6px;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #1b5e20;
  background-color: #c8e6c9;
  color: #1b5e20;
}
