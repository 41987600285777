/* AdminPage.css */

.admin-page {
    display: flex;
    height: 95vh; /* Full viewport height */
    font-family: Arial, sans-serif;
    background-color: #f0f0f0; /* Pleasant grey background for main page */
    overflow: hidden; /* Prevent overflow to remove scrollbar */
}

.sidebar {
    width: 250px; /* Increased width for better visibility */
    height: 95vh; /* 95% of viewport height */
    background-color: #ffffff; /* White background */
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for floating effect */
    position: fixed; /* Make sidebar floating */
    left: 20px; /* Position from the left */
    top: 2.5vh; /* Top margin to center vertically */
    overflow-y: auto; /* Scroll if content overflows */
}

.admin-info {
    margin-bottom: 20px;
}

.admin-info h2 {
    color: #4285f4; /* Google Blue */
    margin-bottom: 10px;
    font-size: 24px;
}

.admin-info h4 {
    color: #333333;
    font-size: 16px;
}

.course-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.course-box {
    display: flex;
    align-items: center;
    background-color: #4285f4; /* Google Blue */
    color: #ffffff;
    padding: 10px 15px; /* Reduced padding for smaller height */
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 50px; /* Fixed height for consistency */
}

.course-box:hover {
    background-color: #3367d6; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift on hover */
}

.logo-placeholder {
    width: 30px;
    height: 30px;
    background-color: #ffffff; /* White background for logo */
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    flex-shrink: 0;
}

.course-name {
    flex-grow: 1;
    display: flex;
    align-items: center; /* Vertically center the text */
}

.content {
    margin-left: 300px; /* Space for the fixed sidebar (250px width + 50px margin) */
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between sections */
    box-sizing: border-box;
    height: 100%;
    background-color: #f0f0f0; /* Grey background */
    overflow-y: auto; /* Enable vertical scrolling within the content */
}

.select-course-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(95vh - 40px); /* Adjust height based on sidebar */
    color: #555555;
    font-size: 20px;
    text-align: center;
}

.admin-tabs {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Tabs Customization */
.react-tabs__tab-list {
    border-bottom: 2px solid #ccc;
    padding-left: 0;
    margin: 0 0 20px 0;
    display: flex;
    list-style: none;
}

.react-tabs__tab {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-bottom: none;
    padding: 10px 20px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    font-size: 16px;
    margin-right: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.react-tabs__tab--selected {
    background: #ffffff;
    border-color: #ccc;
    color: #000;
    font-weight: bold;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}
