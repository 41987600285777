/* FileMenu.css */

/* The right-pinned container */
.file-menu-right {
    position: absolute; /* or fixed, if you want a floating panel */
    top: 2.5vh;
    right: 20px;
    width: 300px;
    height: 90vh;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

    overflow-y: auto;
    overflow-x: visible;

    z-index: 9999;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    transition: transform 0.3s ease;
    transform: translateX(0);
}
.file-menu-right.collapsed {
    transform: translateX(110%);
}

/* The collapse "tab" on the left */
.collapse-button {
    position: absolute;
    top: 10px;
    left: -60px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 4px 0 0 4px;
    background-color: #ccc;
    color: #333;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: background-color 0.2s;
}
.collapse-button:hover {
    background-color: #bbb;
}

/* Section titles (Lecture Materials, Homework, etc.) */
.file-menu-title {
    margin: 15px 0 5px;
    padding: 0 15px;
    font-size: 18px;
    font-weight: 600;
}

/* A horizontal divider line */
.file-menu-divider {
    margin: 15px 0;
    border: 0;
    border-top: 1px solid #ccc;
}

/* ~~~~~ PINNED FILES SECTION ~~~~~ */

/* 
   Add top padding so there's extra space before this bounding box starts.
   Also add left padding so it's not flush with the container edge.
*/
.pinned-file-wrapper {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 6px;

    /* Extra top spacing from whatever is above it */
    margin: 20px 15px 10px 15px;

    /* Add more interior padding so there's space around the pinned items */
    padding: 15px;
}

/* Pinned section heading (no emoji) */
.pinned-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px; /* put a gap before pinned items */
}

/* ~~~~~ PIN ICON ~~~~~ */

/* 
   We make the background color yellow (#FFD700) 
   and overlay an SVG of a push pin.
   Added some padding so the pin graphic
   isn't touching the bounding box edges.
*/
.pinnedfile-icon {
    background-color: #FFD700 !important;
    background-image: url("pin-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70% 70%;

    /* Add padding inside so it doesn’t look cramped */
    padding: 6px;
}

/* ~~~~~ WEEK SECTIONS ~~~~~ */

/* The bounding box for “Week X” blocks */
.week-wrapper {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 6px;
    margin: 20px 15px 10px 15px; /* top: 20px, so there's space from prior element */
    padding: 15px;
}

/* The clickable subheading (Week 1, etc.) as a button-like box */
.subheading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
    padding: 8px 12px;

    /* We remove margin-bottom so the gap between subheading & first file 
       is handled by .week-files. 
    */
    margin-bottom: 0;

    cursor: pointer;
}
.subheading:hover {
    background-color: #e6e6e6;
}

/* The container for files in a week, with a vertical "branch" line. 
   We'll add top margin to create a gap between subheading & first file. 
*/
.week-files {
    display: none;
    flex-direction: column;
    gap: 8px;
    position: relative;

    margin-top: 10px; /* space from the subheading box */
    margin-left: 20px;
    border-left: 2px solid #ddd;
    padding-left: 10px;
}
.week-files.expanded {
    display: flex;
}

/* ~~~~~ FILE ITEMS ~~~~~ */

/* Grey background, with left padding. */
.file-item {
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 15px; 
    border-radius: 6px;
    background-color: #f0f0f0;
    margin-bottom: 5px;
    cursor: pointer;
}
.file-item:hover {
    background-color: #e7e7e7;
}

/* The 32×32 icon box. 
   pinnedfile-icon overrides color with #FFD700 + pinned icon. 
*/
.file-icon {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background-color: #ccc;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    font-weight: 700;
    color: #fff;
    font-size: 10px;
}
.pdf-icon {
    background-color: #f5222d; 
}
.doc-icon {
    background-color: #007bff;
}
.ppt-icon {
    background-color: #f2790f;
}

/* File name text */
.file-name {
    font-size: 13px;
    color: #333;
    word-wrap: break-word;
    max-width: 180px;
}

/* The sticky search bar at bottom */
.search-container {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 10px 15px;
    box-shadow: 0 -2px 6px rgba(0,0,0,0.05);
}
.search-bar {
    width: 100%;
    padding: 6px;
    font-size: 13px;
    box-sizing: border-box;
}
/* FileMenu.css */

/* The container itself */
.file-menu-right {
    position: absolute; /* or fixed, if you want */
    top: 2.5vh;
    right: 20px;
    width: 300px;
    height: 90vh;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  
    overflow-y: auto;
    overflow-x: visible;
  
    z-index: 9999;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  
    transition: transform 0.3s ease;
    transform: translateX(0);
  }
  
  .file-menu-right.collapsed {
    transform: translateX(110%);
  }
  
  /* The "tab" or "collapse-button" is offset to the left 
     so it's visible even if the menu is collapsed. */
  .collapse-button {
    position: absolute;
    top: 10px;
    left: -40px; /* put it 40px outside the container */
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 4px 0 0 4px;
    background-color: #ccc;
    color: #333;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s, left 0.2s;
    z-index: 10000; /* on top of everything */
  }
  .collapse-button:hover {
    background-color: #bbb;
  }
  /* FileMenu.css */

/* The right-pinned container */
.file-menu-right {
    position: absolute; /* or fixed, if you prefer a floating panel */
    top: 2.5vh;
    right: 20px;
    width: 300px;
    height: 90vh;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  
    /* We specifically allow horizontal overflow so the button can stick out */
    overflow-y: auto;
    overflow-x: visible;
  
    z-index: 9999;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  
    transition: transform 0.3s ease;
    transform: translateX(0);
  }
  
  .file-menu-right.collapsed {
    /* Move the panel off screen, but the button will remain visible 
       if we place it outside with left: -somePX */
    transform: translateX(110%);
  }
  
  /* The small "tab" or collapse-button */
  .collapse-button {
    position: absolute;
    top: 10px;
    left: -40px; /* The button is 40px to the left of the panel, so it juts out */
    width: 40px;
    height: 40px;
  
    border: none;
    border-radius: 4px 0 0 4px;
    background-color: #ccc;
    color: #333;
    cursor: pointer;
    font-weight: bold;
  
    /* Keep it in front of the panel and anything else */
    z-index: 10000;  
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: background-color 0.2s;
  }
  
  .collapse-button:hover {
    background-color: #bbb;
  }
  