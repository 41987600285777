/* src/chatbot_components/MainInterface/Settings/UserPreferencesOverlay.css */

.overlay-card {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dim background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it's on top */
  }
  
  .overlay-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto; /* Make content scrollable if needed */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background-color: #555;
  }
  
  h2 {
    margin-top: 0;
    margin-bottom: 15px;
  }
  