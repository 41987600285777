:root {
    /* Primary Colors */
    --primary-blue: #1e90ff;
    --primary-blue-hover: #1c86ee;
  
    --primary-red: #ff4d4d;
    --primary-red-hover: #ff3333;
  
    /* Secondary Colors */
    --secondary-green: #32cd32;
    --secondary-green-hover: #2eb82e;
  
    /* Neutral Colors */
    --neutral-gray: #f0f0f0;
    --neutral-gray-hover: #e6e6e6;
    --dark-gray: #333333;
  
    /* Common */
    --white: #ffffff;
  }
  