/* src/chatbot_components/MainInterface/Menu/PedagogicalOutcomesOverlay.css */

/* Dark, semi-transparent background covering the screen */
.outcomes-overlay-card {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* White card with the scrollable content */
.outcomes-overlay-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 900px;
    max-height: 75%;
    overflow-y: auto; /* Make content scrollable if it exceeds max-height */
}

/* Close button in the top-right corner */
.outcomes-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 1100;
}

.outcomes-close-button:hover {
    background-color: #555;
}

/* Headings and paragraphs */
h2, h3 {
    margin-top: 20px;
    color: #333;
}

p, ul {
    margin-bottom: 20px;
    font-size: 0.95rem;
    color: #555;
}

ul {
    padding-left: 20px;
    list-style-type: disc;
}

ul li {
    margin-bottom: 10px;
}
