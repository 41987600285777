* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  .gened1091 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .chat-container {
    width: 100%;
    max-width: 600px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: white;
    overflow: hidden;
  }
  
  .chat-box {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .user-message {
    text-align: right;
    color: #007bff;
  }
  
  .assistant-message {
    text-align: left;
    color: #333;
  }
  
  .input-container {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
    background: #f9f9f9;
  }
  
  input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  