/* PrivacyPolicyOverlay.css */
.overlay-card {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dim background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.overlay-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    max-height: 90%;
    overflow-y: auto; /* Ensure content is scrollable */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 1100; /* Ensure the button is above all other elements */
}

.close-button:hover {
    background-color: #555; /* Add a hover effect */
}

h2, h3 {
    margin-top: 20px;
}

p, ul {
    margin-bottom: 20px;
}

ul {
    padding-left: 20px; /* Indent bullets */
}

ul li {
    margin-bottom: 10px;
}
