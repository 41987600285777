/* src/chatbot_components/MainInterface/Menu/WhatsNewOverlay.css */
.whatsnew-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  
  .overlay-inner {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    max-height: 90%;
    overflow-y: auto;
  }
  
  /* Slide content area */
  .slide-content {
    position: relative;
    text-align: center;
  }
  
  .slide-title {
    margin: 10px 0 15px 0;
    font-size: 24px;
    font-weight: bold;
  }
  
  .slide-image {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-bottom: 12px;
  }
  
  .slide-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  /* Arrows */
  .nav-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #444;
    color: #fff;
    border: none;
    font-size: 24px;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    opacity: 0.8;
  }
  .nav-arrow:hover {
    opacity: 1;
  }
  .left-arrow {
    left: 10px;
  }
  .right-arrow {
    right: 10px;
  }
  
  /* Dot indicators */
  .dots-container {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
  .dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .dot.active {
    background-color: #007bff;
  }
  
  /* Close button top-right corner */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: bold;
    z-index: 2100;
  }
  .close-button:hover {
    background-color: #555;
  }
  