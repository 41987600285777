/* src/chatbot_components/MainInterface/Nodes/VisualSelectionNode.css */

.visual-selection-node {
  width: 650px;
  border: 2px solid #2196f3;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 20px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.visual-selection-title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/* We'll do 4 columns as well, each 140px, to match the width nicely */
.visual-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 140px);
  grid-gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: start;
}

.visual-card {
  position: relative;
  background-color: #fff;
  border: 1px solid #90caf9;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: box-shadow 0.2s ease-in-out;
  padding: 10px;
}

.visual-card:hover {
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}

.visual-card-image-container {
  position: relative;
  width: 120px;
  height: 80px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 4px;
}

.visual-card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* The overlay that appears on hover */
.visual-card-hover {
  position: absolute;
  inset: 0;
  background: rgba(250, 250, 250, 0.95);
  color: #333;
  padding: 6px;
  font-size: 0.7rem; /* smaller text */
  opacity: 0;
  transform: translateY(20%);
  transition: opacity 0.25s, transform 0.25s;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.visual-card-image-container:hover .visual-card-hover {
  opacity: 1;
  transform: translateY(0);
}

.visual-card-label {
  margin-top: 8px;
  border-top: 1px solid #eee;
  padding-top: 6px;
  font-size: 0.85rem;
}

/* Green highlight if selected */
.visual-card.used-card {
  border: 2px solid #4caf50 !important;
  box-shadow: 0 0 4px rgba(76,175,80,0.5);
}

/* Grey out the unselected ones if one is chosen */
.visual-card.greyed-out {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}
