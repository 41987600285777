/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #eef1f5;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Version Number Footer */
.version-footer {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 12px;
  color: #888;
  cursor: pointer;
}

/* Version Pop-up Card */
.version-popup {
  position: fixed;
  bottom: 80px;
  right: -400px;
  width: 320px;
  max-height: 400px;
  background: #fff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow-y: auto;
  padding: 20px;
  z-index: 100;
  transition: right 0.4s ease;
}

.version-popup.visible {
  right: 50px;
}

.version-popup h3 {
  margin-top: 0;
  font-size: 18px;
  color: #333;
}

.version-popup p {
  font-size: 14px;
  margin: 10px 0;
  color: #666;
}

/* Close button for Pop-ups */
.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 16px;
  color: #888;
  cursor: pointer;
}

.close-popup:hover {
  color: #000;
}

/* Landing Page */
.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: calc(100vh);
  box-sizing: border-box;
}

/* Landing Card */
.landing-card {
  display: flex;
  width: 1000px;
  max-width: 95%;
  height: 650px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  background-color: #fff;
}

/* Login Side */
.login-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 40px 50px;
}

.login-side h2 {
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.login-form, .signup-form, .forgot-password-form {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.login-form label, .signup-form label, .forgot-password-form label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
  font-weight: 500;
  text-align: left;
}

.login-form input, .signup-form input, .forgot-password-form input {
  width: 100%;
  padding: 14px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
  padding-left: 20px;
}

.login-button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  padding: 14px 0;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.login-button:hover {
  background-color: #004494;
}

.create-account, .forgot-password-container {
  text-align: right;
  margin-bottom: 20px;
}

.create-account-link, .forgot-password {
  font-size: 16px;
  color: #0056b3;
  text-decoration: none;
  cursor: pointer;
}

.create-account-link:hover, .forgot-password:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 0.9em;
  text-align: center;
}

/* Graphic Side */
.graphic-side {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color 1s ease;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  z-index: 3;
}

.graphic-side.transitioning .rotating-image {
  opacity: 0;
  transition: opacity 1s ease;
}

.rotating-image {
  width: 100%;
  height: 100%;
  flex: 1;
  object-fit: cover;
  transition: opacity 1s ease;
}

.progress-bar {
  position: absolute;
  bottom: 30px;
  left: 20px;
  right: 20px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.7);
  animation: progressBar 9s linear infinite;
  border-radius: 3px;
}

@keyframes progressBar {
  0% { width: 0; }
  100% { width: 100%; }
}

/* Form Container */
.form-container {
  display: flex;
  position: relative;
  width: 100%;
  transition: transform 0.6s ease-in-out;
  margin-left: 40px;
}

.form-container.slide-left {
  transform: translateX(-127%);
}

.form-container.slide-right {
  transform: translateX(118%);
}

/* Sign Up and Forgot Password Forms */
.signup-form, .forgot-password-form {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.signup-form {
  left: 130%;
}

.forgot-password-form {
  right: 130%;
}

.password-fields {
  display: flex;
  flex-direction: column;
}

.password-fields label {
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .landing-card {
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .login-side, .graphic-side {
    border-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .graphic-side {
    padding: 0;
  }

  .progress-bar {
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
}

/* Spacing Adjustments */
.create-account {
  margin-top: 0px;
}

/* Status Footer */
.status-footer {
  position: absolute;
  bottom: 10px;
  left: 20px;
  font-size: 12px;
  cursor: pointer;
}

.status-footer.status-active .status-text {
  color: green;
}

.status-footer.status-inactive .status-text {
  color: red;
}

.status-footer .status-time {
  color: #888;
}

.status-footer .status-text {
  font-weight: bold;
}

/* Ensure each span is on a new line */
.status-footer span {
  display: block;
}

/* Status Pop-up Card */
.status-popup {
  position: fixed;
  bottom: 80px;
  left: -400px;
  width: 320px;
  max-height: 400px;
  background: #fff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow-y: auto;
  padding: 20px;
  z-index: 100;
  transition: left 0.4s ease;
}

.status-popup.visible {
  left: 50px;
}

.status-popup h3 {
  margin-top: 0;
  font-size: 18px;
  color: #333;
}

.status-popup p, .status-popup ul {
  font-size: 14px;
  margin: 10px 0;
  color: #666;
}

.status-popup ul {
  padding-left: 20px;
}

.status-popup ul li {
  margin-bottom: 5px;
}
