/* DesignChatbot.css */

.design-chatbot-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .design-chatbot-container h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .design-chatbot-container p {
    font-size: 16px;
    margin: 5px 0;
  }
  
  .assistant-id {
    background-color: #e0e0e0;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: bold;
    font-size: 14px;
    color: #666;
    display: inline-block;
  }
  
  .assistant-id:hover {
    background-color: #d6d6d6;
  }
  .design-chatbot-container .system-instructions {
    width: 96%;
    height: 300px; /* Doubled the height from 150px to 300px */
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
  }
  
  .system-instructions:focus {
    outline: none;
    border-color: #4285f4;
    box-shadow: 0 0 5px rgba(66, 133, 244, 0.5);
  }
  
  .save-button {
    background-color: #ccc;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: not-allowed;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.save-button.active {
    background-color: #4285f4; /* Google Blue */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.save-button.active:hover {
    background-color: #3367d6; /* Darker blue */
}

.save-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}
.save-button {
    background-color: #ccc;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: not-allowed;
    position: relative;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    display: inline-flex;
    align-items: center;
    gap: 10px; /* Space between text and icon */
}

.save-button.active {
    background-color: #4285f4; /* Google Blue */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.save-button.active:hover {
    background-color: #3367d6; /* Darker blue */
}

.save-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.save-icon {
    font-size: 16px;
    display: inline-block;
    margin-left: 8px;
}

.spinning {
    animation: spin 1s linear infinite;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 50%;
    border-top: 2px solid #4285f4; /* Blue for the spinning top */
}

.checkmark {
    font-size: 18px;
    color: #4caf50; /* Green color for success */
}

/* Spinning animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* Tooltip container */
.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: help; /* Change the cursor to indicate helpful information */
}

.tooltip-container .tooltip-text {
    visibility: hidden;
    width: 180px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the element */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    font-size: 12px;
}

/* Arrow pointing to the element */
.tooltip-container .tooltip-text::after {
    content: '';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

/* Show the tooltip when hovering over the container */
.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
