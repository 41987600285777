/* General button styles */
.menu-button {
    display: block;
    width: 90%; /* Uniform width */
    height: 60px; /* Square-like dimensions */
    margin: 5px auto;
    padding: 5px;
    font-size: 16px;
    text-align: center;
    border: none;

    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  /* Admin button */
  .menu-button.admin-button {
    background-color: var(--primary-blue);
    color: var(--white);
  }
  
  .menu-button.admin-button:hover {
    background-color: var(--primary-blue-hover);
    transform: scale(1.05);
  }
  
  /* Privacy Policy button */
  .menu-button.privacy-button {
    background-color: var(--secondary-green);
    color: var(--white);
  }
  
  .menu-button.privacy-button:hover {
    background-color: var(--secondary-green-hover);
    transform: scale(1.05);
  }
  
  /* Default button */
  .menu-button.default-button {
    background-color: var(--neutral-gray);
    color: var(--dark-gray);
  }
  
  .menu-button.default-button:hover {
    background-color: var(--neutral-gray-hover);
    transform: scale(1.05);
  }
  
  /* Logout button */
  .logout-button {
    background-color: var(--primary-red);
    color: var(--white);
    width: 90%; /* Uniform width */
    height: 50px; /* Square-like dimensions */
    margin: 5px auto;

    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .logout-button:hover {
    background-color: var(--primary-red-hover);
    transform: scale(1.05);
  }
  