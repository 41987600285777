/* src/chatbot_components/MainInterface/Nodes/DomainColorPlotterNode.css */

.complex-function-plotter-node {
  background-color: #f9f9f9;
  color: #333;
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  max-width: 1200px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative; /* Important so overlay can position absolutely */
}

.node-label {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 15px;
}

.node-content {
  display: flex;
  justify-content: space-between;
}

.left-section,
.center-section {
  padding: 10px;
}

.left-section {
  width: 250px;
  min-width: 220px;
  border-right: 1px solid #ccc;
}

.center-section {
  flex-grow: 2;
  min-width: 600px;
  padding: 15px;
}

.section-label {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.error {
  color: red;
}

.loading {
  color: green;
}

.no-data {
  text-align: center;
  color: gray;
}

input[type='text'],
input[type='number'] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* --- The full-node overlay when user isn't holding Cmd --- */
.command-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.35);
  z-index: 9999; /* Keep this high so it covers all node content */
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto; /* So it blocks clicks until cmd is pressed */
  border-radius: 10px;
}

.overlay-text {
  color: #282828;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.command-key-symbol {
  font-family: sans-serif;
  font-weight: bolder;
  padding: 2px 4px;
  background-color: #fff;
  border-radius: 4px;
  margin: 0 4px;
}
