.upload-view-pdf-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.upload-view-pdf-container h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.upload-view-pdf-container p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.file-list {
    margin-top: 10px;
    margin-bottom: 20px;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9f9f9;
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.file-item span {
    font-size: 14px;
    color: #333;
}

.delete-button {
    background-color: #ff6f61;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-button:hover {
    background-color: #e65a50;
}

.upload-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.upload-label {
    background-color: #4285f4;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: inline-block;
    margin-bottom: 10px;
}

.upload-label:hover {
    background-color: #3367d6;
}

#file-upload {
    display: none;
}

.error {
    color: red;
    font-size: 14px;
}
