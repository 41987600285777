/* src/chatbot_components/MainInterface/CourseSelectorModal.css */

/* Full-screen grey overlay */
.course-selector-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 9999; /* on top of everything */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* The inner white box */
  .course-selector-modal {
    background-color: #fff;
    border-radius: 8px;
    width: 400px;
    padding: 20px 20px 30px; /* left, right, plus bottom space if needed */
    text-align: center;
    position: relative; /* so the close icon can be absolutely placed */
  }
  
  /* The “X” in top-right corner */
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #333;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 25px; /* vertically centers 'X' */
    text-align: center;
    cursor: pointer;
    font-weight: 600;
  }
  
  /* Title area */
  .course-selector-modal h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .course-selector-modal p {
    margin: 0 0 15px;
  }
  
  /* Container for the “cards” */
  .course-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
  }
  
  /* Each “class” card */
  .course-card {
    background-color: #e3f2fd;
    border: 2px solid #90caf9;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    transition: box-shadow 0.2s ease;
  }
  
  .course-card:hover {
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  }
  /* Add at the bottom of src/chatbot_components/MainInterface/CourseSelectorModal.css */

/* The selected course gets a darker border & subtle background */
.active-course-card {
    border-color: #333 !important;  /* darker gray border */
    background-color: #f5f5f5;      /* subtle grey background (optional) */
  }
  