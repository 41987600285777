/* Define CSS variables for light and night modes */
:root {
  --background-color: #f2f2f7;
  --text-color: #000000;
  --sidebar-background: rgba(140, 140, 151, 0.73);
  --input-background: #e0e0e0; /* Adjusted for darker floating bar */
  --button-background: #e5e5ea;
  --button-hover-background: #d1d1d6;
  --logout-button-background: #ff3b30;
  --logout-button-hover-background: #cc2e25;
  --border-color: #ccc;
  --node-background: #ffffff;
  --node-border: #e5e5ea;
  --handle-color: #007aff;
  --reactflow-background: #ffffff;
  --reactflow-dot-color: #000000;
}

.night-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --sidebar-background: #1e1e1e;
  --input-background: #1a1a1a; /* Adjusted for darker floating bar */
  --button-background: #3e3e3e;
  --button-hover-background: #4e4e4e;
  --logout-button-background: #ff3b30;
  --logout-button-hover-background: #cc2e25;
  --border-color: #444444;
  --node-background: #1e1e1e;
  --node-border: #444444;
  --handle-color: #bbbbbb;
  --reactflow-background: #000000;
  --reactflow-dot-color: #ffffff;
}

/* General styles */
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling */
  background-color: var(--background-color);
  color: var(--text-color);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Container for the entire application */
.newgpt-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
}


/* ReactFlow full background */
.flow-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.reactflow-background {
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: var(--reactflow-background) !important;
}

.react-flow__background {
  background-color: var(--reactflow-background) !important;
}

.react-flow__background-dot {
  stroke: var(--reactflow-dot-color);
}

/* Sidebar Menu */
.sidebar-menu {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 225px;
  background-color: var(--sidebar-background);
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-y: auto;
  flex-shrink: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.logo-placeholder {
  text-align: center;
  margin-bottom: 20px;
}

.logo-image {
  width: 95%;
  height: auto;
  border-radius: 15px;
}

.centered {
  text-align: center;
  margin-top: 10px;
  color: var(--text-color);
}

.horizontal-bar {
  border-bottom: 1px solid var(--border-color);
  margin: 20px 0;
}

.menu-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.menu-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: var(--button-background);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
  color: var(--text-color);
}

.menu-button:hover {
  background-color: var(--button-hover-background);
}

.logout-button {
  margin-top: 20px;
  padding: 15px;
  background-color: var(--logout-button-background);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.logout-button:hover {
  background-color: var(--logout-button-hover-background);
}

/* Node styles */
.user-node,
.chatbot-node,
.visual-node,
.initial-options-node {
  border-radius: 10px;
  padding: 15px;
  user-select: text;
  overflow: visible;
  box-sizing: border-box;
  background-color: var(--node-background);
  color: var(--text-color);
  border: 2px solid var(--node-border);
  position: relative;
}

.reference-node {
  border-radius: 10px;
  padding: 15px;
  user-select: text;
  overflow: visible;
  box-sizing: border-box;
  background-color: rgb(124, 197, 124);
  color: var(--text-color);
  border: 2px solid rgb(73, 239, 27);
  position: relative;
}

/* User node */
.user-node {
  width: 500px;
  max-width: 500px;
  text-align: left;
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

/* Chatbot node */
.chatbot-node {
  width: 500px;
  max-width: 500px;
  text-align: left;
  font-size: 12px;
  position: relative;
  padding-bottom: 50px; /* Adjusted padding */
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.chatbot-content {
  width: 100%;
}

/* Chatbot node icons */
.chatbot-node-icons {
  position: absolute;
  bottom: 5px; /* Adjusted to ensure buttons are visible */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.chatbot-node-icon-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--node-background);
  border: 2px solid var(--node-border);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chatbot-node-icon-button:hover {
  background-color: var(--button-hover-background);
}

.chatbot-node-icon {
  font-size: 18px;
}

.copy-icon {
  color: #007aff;
}

.flag-icon {
  color: #ff3b30;
}

/* Reference node */
.reference-node {
  width: 250px;
  text-align: left;
  font-size: 12px;
}

.reference-node a {
  color: var(--text-color);
  text-decoration: underline;
}

.reference-node a:hover {
  text-decoration: none;
}

/* Visual node */
.visual-node {
  width: 400px;
  height: 300px;
  border: 2px solid red;
  background-color: var(--node-background);
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure edges are visible */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  overflow: hidden; /* Ensure content doesn't overflow */
}

.visual-content {
  width: 100%;
  height: 100%;
}

.visual-placeholder {
  font-size: 14px;
  color: #999;
}

/* Resize handle */
.resize-handle {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #ff2d55;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}





.refresh-button {
  background-color: #ff9500;
  color: white;
  padding: 15px 25px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.refresh-button:hover {
  background-color: #cc7a00;
}

/* Initial Options Node */
.initial-options-node {
  text-align: center;
  font-size: 10px;
}

.initial-options-node h2 {
  margin-top: 0;
  font-size: 12px;
}

.initial-options-node .options-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: nowrap;
}

.initial-options-node .option-card {
  width: 120px;
  height: 160px;
  background-color: var(--node-background);
  border: 1px solid #8ac6ff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
}

.initial-options-node .option-card:hover {
  background-color: var(--button-hover-background);
}

.initial-options-node .option-card img {
  width: 100px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.initial-options-node .option-card p {
  font-size: 8px;
  color: var(--text-color);
  margin: 5px;
  padding: 0 5px;
}

.initial-options-node .option-card:hover img {
  filter: grayscale(100%); /* Make the image grey */
  background-color: var(--button-hover-background); /* Add background */
}

.option-card-heading {
  font-size: 10px;
  font-weight: 600;
  margin: 0;
  padding: 6px;
  color: var(--text-color);
  line-height: 1.2;
}

/* Adjust the handles */
.user-node .react-flow__handle,
.chatbot-node .react-flow__handle,
.visual-node .react-flow__handle,
.reference-node .react-flow__handle {
  background: var(--handle-color);
}

/* Ensure arrows are visible */
.react-flow__edge-path {
  stroke-width: 2;
  stroke: #4f4f4f;
  z-index: 2;
}

/* Adjust handles to be visible */
.react-flow__handle {
  z-index: 1;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.modal-content {
  background-color: var(--node-background);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  color: var(--text-color);
}

.modal-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: var(--button-background);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--text-color);
}

.modal-button:hover {
  background-color: var(--button-hover-background);
}

/* Suggested Question Node Styles */
/* Suggested Question Node Styles */
.suggested-question-node {
  background-color: var(--button-background);
  color: var(--text-color);
  border: none;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  width: 180px;
  text-align: center;
}

.suggested-question-node:hover {
  background-color: var(--button-hover-background);
}

.suggested-question-node:disabled,
.suggested-question-node.clicked {
  background-color: #d3d3d3;
  color: #888888;
  cursor: not-allowed;
}

.suggested-question-node:disabled:hover {
  background-color: #d3d3d3;
}

/* Add this style for the suggested questions node */
.suggested-questions-node {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  justify-content: flex-start;
  width: 500px; /* Match the width of chatbot node */
}

.suggested-questions-node .suggested-question-button {
  background-color: var(--button-background);
  color: var(--text-color);
  border: none;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 12px;
}

.suggested-questions-node .suggested-question-button:hover {
  background-color: var(--button-hover-background);
}

/* Add this to your CSS file */

.reference-node {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #19702d;
  border-radius: 5px;
  width: 200px; /* Adjust as needed */
}

.reference-node h4 {
  margin-top: 0;
}

.reference-node ul {
  list-style-type: disc;
  padding-left: 20px;
}

.reference-node li {
  margin-bottom: 5px;
}

/* newgpt.css */

/* General Styles */

.reference-node {
  border: 1px solid #ffc107;
  width: 300px;
}

.visual-node {
  width: 400px;
  height: 300px;
}

.initial-options-node {
  width: 600px;
}

.suggested-questions-node {
  display: flex;
  flex-direction: column;
}

/* Reference Node */
.reference-node .pdf-viewer {
  margin-top: 10px;
  width: 100%;
  height: 400px;
}

.reference-node .download-button {
  margin-top: 10px;
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.reference-node .download-button svg {
  margin-right: 5px;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-button {
  padding: 10px;
  margin-top: 10px;
}

/* Option Cards */
.options-container {
  display: flex;
  justify-content: space-around;
}

.option-card {
  width: 180px;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
}

.option-card-heading {
  margin-bottom: 10px;
}

.option-card img {
  max-width: 100%;
  height: auto;
}

.option-card p {
  margin-top: 10px;
}

/* Suggested Questions Node */
.suggested-questions-node {
  width: 500px;
  padding: 10px;
}

.suggested-question-button {
  margin-bottom: 5px;
  padding: 10px;
  cursor: pointer;
}

/* Add these styles to your existing CSS */

/* Visual Node Styles */
.visual-node,
.complex-function-plotter-node {
  position: relative;
  border: 2px solid var(--node-border);
  border-radius: 10px;
  overflow: hidden;
}

.visual-node.interactive,
.complex-function-plotter-node.interactive {
  border-color: red;
}

.interactivity-toggle {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--button-background);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;
}

.interactivity-toggle.active {
  color: green;
}

.interactivity-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}

.overlay-text {
  font-size: 14px;
  color: black;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
}

/* Adjust the visual-node to prevent scroll conflicts */
.visual-node,
.complex-function-plotter-node {
  pointer-events: auto;
}

.visual-node.interactive,
.complex-function-plotter-node.interactive {
  pointer-events: auto;
}

/* Add these styles to your existing CSS */

/* Visual Node Styles */
.visual-node,
.complex-function-plotter-node {
  position: relative;
  border: 2px solid var(--node-border);
  border-radius: 10px;
  overflow: hidden;
}

.visual-node.interactive,
.complex-function-plotter-node.interactive {
  border-color: red;
}

.interactivity-toggle {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--button-background);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s;
  z-index: 2;
}

.visual-node:hover .interactivity-toggle,
.complex-function-plotter-node:hover .interactivity-toggle {
  opacity: 1; /* Show on hover */
}

.interactivity-toggle.active {
  color: green;
}

.interaction-blocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}

.overlay-text {
  font-size: 14px;
  color: black;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
}

/* Adjust the visual-node to prevent scroll conflicts */
.visual-node,
.complex-function-plotter-node {
  pointer-events: auto;
}

.visual-node.interactive .interaction-blocker,
.complex-function-plotter-node.interactive .interaction-blocker {
  display: none; /* Hide the blocker when interactive */
}

/* Add this to your CSS file */

.fourier-series-node {
  width: 600px; /* Adjust as needed */
  height: 400px; /* Adjust as needed */
  position: relative;
  border: 2px solid var(--node-border);
  border-radius: 10px;
  overflow: hidden;
}

.fourier-series-node .visual-content {
  width: 100%;
  height: 100%;
}

.fourier-series-node.interactive {
  border-color: red;
}

/* Ensure the visualization does not interfere with scrolling */
.fourier-series-node {
  pointer-events: auto;
}

.fourier-series-node .interaction-blocker {
  display: none; /* Hide any interaction blockers if present */
}

.fourier-series-node {
  width: 800px; /* Increase width if needed */
  height: 600px; /* Increase height to accommodate controls */
  position: relative;
  border: 2px solid var(--node-border);
  border-radius: 10px;
  overflow: visible; /* Change to visible to allow overflow */
}
/* File name: SK.css or whichever you import in SK.js */

/* The container that positions the button at bottom-right */
.menu-toggle-button-container {
  position: fixed;
  bottom: 20px;   /* adjust as you like */
  right: 20px;    /* adjust as you like */
  z-index: 99999; /* ensure it's on top */
}

/* The button styling */
.menu-toggle-button {
  background-color: #ffb703;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.menu-toggle-button:hover {
  background-color: #ffa200;
}
